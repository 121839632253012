import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { SharedMaterialModule } from '../shared-material/shared-material.module';

import { GixamPageTitleComponent } from '../../components/gixam-page-title/gixam-page-title.component';
import { GixamDevicesTableFilterComponent } from '../../components/gixam-devices-table-filter/gixam-devices-table-filter.component';
import { GixamStudiesTableFilterComponent } from '../../components/gixam-studies-table-filter/gixam-studies-table-filter.component';
import { GixamDevicesTableComponent } from '../../components/gixam-devices-table/gixam-devices-table.component';
import { GixamStudiesTableComponent } from '../../components/gixam-studies-table/gixam-studies-table.component';
import { GixamFileUploadComponent } from '../../../gixam/studies/studies-table/gixam-file-upload/gixam-file-upload.component';
import { GixamLoaderComponent } from '../../components/gixam-loader/gixam-loader.component';
import { RouterModule } from '@angular/router';
import { CreateClinicFormComponent } from '../../components/create-clinic-form/create-clinic-form.component';
import { NgxUiLoaderModule, POSITION, SPINNER } from 'ngx-ui-loader';
import { MissingTranslationHandler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { GixamCustomTranslationLoader } from 'src/app/translate/gixam-custom-translation-loader';
import { JubaanLanguagesService } from 'src/app/services/jubaan-languages.service';
import { GixamMissingTranslationHandler } from 'src/app/translate/gixam-missing-translation-handler';
import { GixamStripeComponent } from '../../components/gixam-stripe/gixam-stripe.component';
import { NgxStripeModule } from 'ngx-stripe';
import { environment } from 'src/environments/environment';
import { GixamShoppingCartComponent } from '../../components/gixam-shopping-cart/gixam-shopping-cart.component';
import { GixamProductsTableComponent } from '../../components/gixam-products-table/gixam-products-table.component';
import { GixamProductsListComponent } from '../../components/gixam-products-list/gixam-products-list.component';
import { ShipmentAddressFormComponent } from '../../components/gixam-shipment-address-form/gixam-shipment-address-form.component';
import { PersonalDetailsFormComponent } from '../../components/gixam-personal-details-form/gixam-personal-details-form.component';
import { GixamPhoneFormComponent } from '../../components/gixam-phone-form/gixam-phone-form.component';
import { ContactUsFormComponent } from '../../components/contact-us-form/contact-us-form.component';
import { GixamShoppingCartDigitalItemComponent } from '../../components/gixam-shopping-cart/gixam-shopping-cart-digital-item/gixam-shopping-cart-digital-item.component';
import { GixamShoppingCartPhysicalItemComponent } from '../../components/gixam-shopping-cart/gixam-shopping-cart-physical-item/gixam-shopping-cart-physical-item.component';
import { GixamStudyRecordingDialogComponent } from '../../components/gixam-study-recording-dialog/gixam-study-recording-dialog.component';

@NgModule({
  declarations: [
    GixamStudiesTableComponent,
    GixamDevicesTableComponent,
    GixamPageTitleComponent,
    GixamDevicesTableFilterComponent,
    GixamStudiesTableFilterComponent,
    GixamFileUploadComponent,
    GixamLoaderComponent,
    CreateClinicFormComponent,
    GixamStripeComponent,
    GixamShoppingCartComponent,
    GixamShoppingCartDigitalItemComponent,
    GixamShoppingCartPhysicalItemComponent,
    GixamProductsTableComponent,
    GixamProductsListComponent,
    ShipmentAddressFormComponent,
    PersonalDetailsFormComponent,
    GixamPhoneFormComponent,
    ContactUsFormComponent,
    GixamStudyRecordingDialogComponent
  ],
  imports: [
    CommonModule,
    RouterModule,    
    ReactiveFormsModule,
    TranslateModule.forChild({
      extend: true,
      loader: {provide: TranslateLoader, useClass: GixamCustomTranslationLoader, deps:[JubaanLanguagesService]},
      missingTranslationHandler: {provide: MissingTranslationHandler, useClass: GixamMissingTranslationHandler},
    }),
    FormsModule,
    HttpClientModule,
    SharedMaterialModule,
    NgxUiLoaderModule,
    NgxStripeModule.forRoot(environment.stripeKey)
  ],
  exports:[
    CommonModule,
    RouterModule,
    TranslateModule,
    SharedMaterialModule,
    ReactiveFormsModule,
    FormsModule,
    NgxUiLoaderModule,
    NgxStripeModule,
    GixamStudiesTableComponent,
    GixamDevicesTableComponent,
    GixamPageTitleComponent,
    GixamDevicesTableFilterComponent,
    GixamStudiesTableFilterComponent,
    GixamFileUploadComponent,
    GixamLoaderComponent,
    CreateClinicFormComponent,
    GixamStripeComponent,
    GixamProductsTableComponent,
    GixamShoppingCartComponent,
    GixamProductsListComponent,
    ShipmentAddressFormComponent,
    PersonalDetailsFormComponent,
    GixamPhoneFormComponent,
    ContactUsFormComponent,
    GixamStudyRecordingDialogComponent
  ]
})
export class SharedModule { }
