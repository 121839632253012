<div class="dialog-wrapper register-existing-device-dialog">
    <div class="dialog-title">
        <button class="close-button" [mat-dialog-close]="false" type="button" tabindex="-1" mat-icon-button
            aria-label="Close">
            <mat-icon class="material-icons notranslate">clear</mat-icon>
        </button>
        <h3>{{'STUDY_RECORDING' | translate}}</h3>
    </div>
    <div class="dialog-content">
        <video *ngIf="videoUrl" [src]="videoUrl" controls autoplay></video>
    </div>
    <div class="dialog-actions">       
        <button mat-raised-button id="btn-close" color="default" (click)="close()">
            <span>{{'CLOSE' | translate}}</span>
        </button>
    </div>
</div>