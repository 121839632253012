<div class="app-header">
    <mat-toolbar>

        <div class="logo-wrapper">
            <img id="jubaan-logo" src="assets/images/gixam_logo.png" />
        </div>
        <div class="nav-links">
            <a [routerLink]="['devices']" routerLinkActive="router-link-active">{{'DEVICES' | translate}}</a>
            <a [routerLink]="['exams']" routerLinkActive="router-link-active">{{'STUDIES' | translate}}</a>
            <a [routerLink]="['appointments']" routerLinkActive="router-link-active">{{'APPOINTMENTS' | translate}}</a>
            <a [routerLink]="['contact-us']" routerLinkActive="router-link-active">{{'CONTACT_US' | translate}}</a>
            <a [routerLink]="['shop']" routerLinkActive="router-link-active">{{'SHOP' | translate}}</a>
        </div>
        <!-- <div class="language-selection-wrapper">
                <button mat-icon-button type="button">
                    <mat-icon>language</mat-icon>
                </button>
            </div>
            <div class="notifications-wrapper">
                <button mat-icon-button type="button">
                    <mat-icon>circle_notifications</mat-icon>
                </button>
            </div> -->

        <div class="notifications-wrapper">
            <button mat-icon-button type="button" color="primary" [matMenuTriggerFor]="cartMenu">
                <mat-icon class="material-symbols-outlined" 
                    matBadge="{{ cartItemCount }}"
                    matBadgeOverlap="true"
                    matBadgeColor="warn"
                    matBadgePosition="above after"
                    matBadgeSize="small"
                    >shopping_cart</mat-icon>
            </button>
            <mat-menu #cartMenu="matMenu"  class="cart-menu" [overlapTrigger]="false" [xPosition]="'before'">
                <!-- <div class="menu-triangle"></div> -->
                <!-- <div> -->
                    <app-gixam-shopping-cart></app-gixam-shopping-cart>                                        
                <!-- </div> -->
            </mat-menu>
        </div>

            <div class="notifications-wrapper">
                <button mat-icon-button type="button" (click)="toProducts()">
                    <mat-icon class="material-icons material-icons-outlined">store</mat-icon>
                </button>
            </div>
            <div class="help-wrapper">
                <button mat-icon-button type="button">
                    <span class="material-symbols-outlined">help</span>
                </button>
            </div>
            <div class="user-wrapper">
                <button type="button" mat-icon-button [matMenuTriggerFor]="menu">
                    <mat-icon class="material-symbols-outlined">account_circle</mat-icon>
                    <!-- <span>{{userName}}</span> -->
                </button>
                <mat-menu #menu="matMenu" [overlapTrigger]="false" [xPosition]="'before'">
                    <button type="button" mat-menu-item><mat-icon class="material-symbols-outlined" color="primary">settings</mat-icon>{{'PREFERENCES' | translate}}</button>
                    <button type="button" mat-menu-item><mat-icon class="material-symbols-outlined" color="primary">settings_account_box</mat-icon>{{'ACCOUNT' | translate}}</button>
                    <mat-divider></mat-divider>
                    <button type="button" mat-menu-item (click)="logout()"><mat-icon class="material-symbols-outlined" color="primary">power_settings_new</mat-icon>{{'LOGOUT' | translate}}</button>
                </mat-menu>
            </div>      
    </mat-toolbar>
</div>