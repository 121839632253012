<div class="component-wrapper">
    <form [formGroup]="studiesFilterForm" class="horizontal-form" (submit)="submitSearchForm()">
        <div class="form-control flex-column">
            <mat-label>{{'FREE_TEXT'| translate}}</mat-label>
            <mat-form-field appearance="outline">
                <input matInput formControlName="freeText"  spellcheck="false"/>
                <button matSuffix mat-icon-button aria-label="Clear" color="default" type="button" *ngIf="studiesFilterForm.get('freeText')?.enabled && studiesFilterForm.get('freeText')?.value" (click)="clearFreeTextField($event)">
                    <mat-icon class="material-icons material-icons-outlined">close</mat-icon>
                </button>
            </mat-form-field>
        </div>

        <div class="form-control flex-column">
            <mat-label>{{'DEVICES' | translate}}</mat-label>
            <mat-form-field appearance="outline">
                <mat-select formControlName="devices" multiple="true" #devicesSelect disableRipple>
                    <mat-select-trigger>
                        <span *ngIf="(studiesFilterForm.get('devices')?.value?.length || 0)">
                            {{studiesFilterForm.get('devices')?.value?.[0].serial || ''}}</span>
                        <span *ngIf="(studiesFilterForm.get('devices')?.value?.length || 0) > 1">
                            (+{{(studiesFilterForm.get('devices')?.value?.length || 0) - 1}}
                            {{(studiesFilterForm.get('devices')?.value?.length === 2 ? 'other' : 'others') |
                            translate}})
                        </span>
                    </mat-select-trigger>            
                    <mat-option *ngFor="let userDevice of (userDevices$ | async)"
                        [value]="userDevice">
                        <div class="mat-option-inner" style="display: flex;flex-direction: row;">
                            <span>{{userDevice.alias}}</span>
                            <span style="font-size: 12px;color:#6F7F84;">({{userDevice.serial}})</span>
                        </div>
                        
                    </mat-option>
                </mat-select>
                <button matSuffix mat-icon-button aria-label="Clear" color="default" *ngIf="studiesFilterForm.get('devices')?.enabled && studiesFilterForm.get('devices')?.value" type="button" (click)="clearDevicesList($event)">
                    <mat-icon class="material-icons material-icons-outlined">close</mat-icon>
                </button>
            </mat-form-field>
        </div>

        <div class="form-control flex-column">
            <mat-label>{{'DATE_RANGE' |translate}}</mat-label>
            <mat-form-field appearance="outline">
                
                <mat-date-range-input [max]="max" formGroupName="dateRange" [rangePicker]="examsDatePicker">
                    <input #startDate matStartDate readonly placeholder="Start date" formControlName="fromDate">
                    <input #endDate matEndDate readonly placeholder="End date" formControlName="toDate"
                        (dateChange)="onEndDateChanged($event)">
                </mat-date-range-input>
                
                <mat-datepicker-toggle matSuffix [for]="examsDatePicker">                
                </mat-datepicker-toggle>
             
                <mat-datepicker-toggle matIconSuffix *ngIf="studiesFilterForm.get('dateRange')?.get('fromDate')?.value || studiesFilterForm.get('dateRange')?.get('toDate')?.value"
                    (click)="studiesFilterForm.get('dateRange')?.reset({'fromDate':null,'toDate': null});">
                    <mat-icon matDatepickerToggleIcon>clear</mat-icon>
                </mat-datepicker-toggle>
                
                <mat-date-range-picker #examsDatePicker touchUi>
                </mat-date-range-picker>
                
            </mat-form-field>
        </div>
        <div class="form-control  flex-column form-control-buttons">            
            <button mat-flat-button type="button" color="default" (click)="resetFilter()">
                <mat-icon class="material-icons material-icons-outlined">search_off</mat-icon>
                {{'CLEAR' | translate}}
            </button>

        </div>
    </form>
</div>