import { HttpEventType } from '@angular/common/http';
import { Component, OnDestroy } from '@angular/core';
import { Subject, Subscription, takeUntil, zip } from 'rxjs';
import { GixamFilesService } from 'src/app/services/gixam-file-upload.service';
import { v4 as uuidv4, v4 } from 'uuid';

@Component({
  selector: 'gixam-file-upload',
  templateUrl: './gixam-file-upload.component.html',
  styleUrls: ['./gixam-file-upload.component.scss']
})
export class GixamFileUploadComponent implements OnDestroy {

  // uploadPercentages: number = 0;
  // uploadedSize: number = 0;
  // formatedUploadedSize: string;
  // totalSize: String;
  // fileName: string | null;

  // selectedFile?: File;

  totalPercentageUploaded:number = 0;
  totalUploadedBytes:number = 0;
  totalFilesSize:number = 0;

  selectedFiles?: File[];

  subscription: Subscription;
  showImportResult: Boolean = false;

  private _onDestory$ = new Subject<void>();
  private onDestory$ = this._onDestory$.asObservable();

  constructor(private filesService: GixamFilesService) { }

  ngOnDestroy(): void {
    this._onDestory$.next();
    this._onDestory$.complete();
    this.subscription?.unsubscribe();
  }

  // onFileSelected(event: any) {

  //   if (!event.target.files) return;

  //   this.uploadPercentages = 0;

  //   this.selectedFile = event.target.files[0];
  //   this.selectedFiles = event.target.files;

  //   const totalFilesSize = Array.from(this.selectedFiles!).reduce((sum: number, file: File) => sum + file.size, 0)

  //   this.totalSize = this.formatUploadedSize(totalFilesSize || 0);

  //   this.fileName = this.selectedFile?.name || null;
    
  //   Array.from(this.selectedFiles!).forEach((file: File) => {
  //     const formData = new FormData();

  //     formData.append('file', file, `${file.name}`);
  //     formData.append('relativePath', file.webkitRelativePath);

  //     this.subscription = this.filesService.upload(formData)
  //       .pipe(takeUntil(this.onDestory$))
  //       .subscribe({
  //         next: (progress: any) => {
  //           this.uploadPercentages = 100 * (progress.uploadedBytes / totalFilesSize);
  //           this.uploadedSize = progress.uploadedBytes;
  //           this.formatedUploadedSize = this.formatUploadedSize(this.uploadedSize);
  //         }, error: (err) => {
  //           console.error('Error uploading file', err);
  //         }
  //       });
  //   });

  //   // this.subscription = this.filesService.uploadFileInChunks(this.selectedFile!, destinationFileName, 500 * 1024)
  //   //   .pipe(takeUntil(this.onDestory$))
  //   //   .subscribe({
  //   //     next: (progress) => {
  //   //       this.uploadPercentages = progress.percentage;
  //   //       this.uploadedSize = progress.uploadedBytes;
  //   //       this.formatedUploadedSize = this.formatUploadedSize(this.uploadedSize);
  //   //     }, error: (err) => {
  //   //       console.error('Error uploading file', err);
  //   //     }
  //   //   });
  // }

  formatUploadedSize(totalUploaded: number) {
    return this.filesService.formatFileSize(totalUploaded);
  }

  cancelUpload() {

    this._onDestory$.next();

    this.subscription?.unsubscribe();
    this.selectedFiles = undefined;
    this.totalPercentageUploaded = 0;
  }

  onFilesSelected(event: any) {
    const files: FileList = event.target.files;

    if (!files || !files.length) return;

    this.totalFilesSize = Array.from(files).reduce((sum:number, file:File)=> sum + file.size,0);

    this.groupFilesAndUpload(files);
  }

  private groupFilesAndUpload(files: FileList) {
    
    const groupedFiles = new Map<string, { file: File; relativePath: string }[]>();

    Array.from(files).forEach((file: File) => {

      const originalPath = file.webkitRelativePath;
      const parts = originalPath.split('/');

      const transformedPath = parts
        .filter((_, index) => index !== 1) // Skip the UserEmail part (second segment)
        .join('/');

      const prefix = parts.slice(0, 1).concat(parts.slice(2, 4)).join('/');

      if (!groupedFiles.has(prefix)) {
        groupedFiles.set(prefix, []);
      }

      groupedFiles.get(prefix)?.push({ file, relativePath: transformedPath });
    });

    groupedFiles.forEach((files, groupKey) => {
      let groupSize = files.reduce((sum:number, item:{file:File, relativePath:string})=> sum + item.file.size,0);

      const formData = new FormData();

      formData.append('groupKey', groupKey); // Send the group key as metadata
      files.forEach(({ file, relativePath }) => {
        formData.append('files', file)
        formData.append('relativePaths', relativePath)
      });

      this.filesService.upload(formData)
        .subscribe(result => {
          if (result.uploadedBytes)
            this.totalPercentageUploaded = (100 *(result.uploadedBytes / this.totalFilesSize));
            this.totalUploadedBytes = result.uploadedBytes;
        });
    });
  }



}
