import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-gixam-study-recording-dialog',
  templateUrl: './gixam-study-recording-dialog.component.html',
  styleUrls: ['./gixam-study-recording-dialog.component.scss']
})
export class GixamStudyRecordingDialogComponent {

  videoUrl:string;

  constructor(@Inject(MAT_DIALOG_DATA) private data:any, private dialogRef:MatDialogRef<GixamStudyRecordingDialogComponent>){}


  ngOnInit(){

    if (this.data && this.data.url){
      this.videoUrl = this.data.url;
    }
  }

  close() {
    this.dialogRef.close(null);
  }
}
