<ng-container *ngIf="{
  searchOptions: searchOptions$ | async,
  pageRowsCount: pageRowsCount$ | async,
  totalRowsCount : totalRowsCount$ | async } as data">
  <div style="position: relative;">
    <mat-table [dataSource]="studiesTableDataSource" matSort (matSortChange)="studiesSortChanged($event)">

      <ng-container matColumnDef="deviceName">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{'DEVICE_NAME' |translate}}</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <div class="cell-inner" style="display: flex;flex-direction: column;">
            <span>{{element.deviceAlias}}</span>
            <span style="font-size: .75rem;color:#6F7F84;font-style: italic;">{{element.deviceSerial}}</span>
          </div>
        </mat-cell>
      </ng-container>

      <!-- <ng-container matColumnDef="deviceName">
        <mat-header-cell *matHeaderCellDef>{{'DEVICE_NAME' |translate}}</mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{element.deviceAlias}}
        </mat-cell>
      </ng-container> -->

      <ng-container matColumnDef="patientNumber">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{'PATIENT_ID' |translate}}</mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.patientNumber}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="studyNumber">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{'STUDY_ID' |translate}}</mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.studyNumber}} </mat-cell>
      </ng-container>

      <!-- <ng-container matColumnDef="age">
            <mat-header-cell *matHeaderCellDef>Age</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{element.age}} </mat-cell>
          </ng-container> -->

      <ng-container matColumnDef="studyDate">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{'STUDY_DATE' |translate}}</mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.studyDate | date : 'dd/MM/yyyy hh:mm'}} </mat-cell>
      </ng-container>

      <!-- <ng-container matColumnDef="importDate">
        <mat-header-cell *matHeaderCellDef>{{'IMPORT_DATE' |translate}}</mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.importDate | date: 'dd/MM/yyyy'}} </mat-cell>
      </ng-container> -->

      <ng-container matColumnDef="actions">
        <mat-header-cell *matHeaderCellDef style="flex:0 0 80px"></mat-header-cell>
        <mat-cell *matCellDef="let element" style="flex:0 0 80px">

          <button *ngIf="element.hasRecording" mat-icon-button color="primary" title="Study Recording"
          (click)="openStudyRecorginDialog($event, element.id)">
            <mat-icon class="material-icons-outlined">videocam</mat-icon>
          </button>

          <!-- <button mat-icon-button color="primary" title="Edit Patient Details"
            (click)="editPatientDetails($event,element)">
            <mat-icon class="material-icons-outlined">person_edit</mat-icon>
          </button> -->
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns; sticky: false"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;" (click)="goToExam(row.id)"></mat-row>

      <tr style="width: 100%;display: flex;" *matNoDataRow>
        <td class="mat-cell" colspan="6" STYLE="width: 100%;">
          <div class="no-results"
            style="margin-top:2rem; border:1px solid #e9e9e9;border-radius:7px;height: 200px; padding: 1rem;display: flex;flex-direction: row;align-items: center;justify-content: center;width: 100%;font-size: 1.5rem;">
            {{'EMPTY_TABLE_RESULTS_NO_{STUDIES}_FOUND.' | translate}}

            <button mat-flat-button color="primary" (click)="importZip()">
              <mat-icon class="material-icons material-symbols-outlined">cloud_upload</mat-icon>
              {{'IMPORT_DATA' | translate}}
            </button>
          </div>

        </td>
    </mat-table>
    <mat-paginator (page)="pageChange($event)" [length]="data.totalRowsCount" [hidePageSize]="true"
      [pageSize]="data?.searchOptions?.pageSize" [pageIndex]="data?.searchOptions?.pageNumber"
      showFirstLastButtons="true" *ngIf="data?.totalRowsCount! > 0">
    </mat-paginator>
    <ngx-ui-loader [loaderId]="'studies-loader'" [hasProgressBar]="false"></ngx-ui-loader>
  </div>
</ng-container>