import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GixamDevicesTableDataSource } from './gixam-devices-table-data-source';
import { GixamDevicesDataService } from 'src/app/services/gixam-devices-data.service';
import { GixamDevicesTableService } from './gixam-devices-table.service';
import { debounceTime, map, shareReplay } from 'rxjs';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { FormControl } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { GixamDeviceStatesEnum } from 'src/app/models/enums/gixam-device-states.enum';
import {GixamDeviceStatusesEnum } from 'src/app/models/enums/gixam-device-statuses.enum';
import { Sort } from '@angular/material/sort';
import { DeviceHubService } from 'src/app/services/signalR/device-hub.service';

@Component({
  selector: 'gixam-devices-table',
  templateUrl: './gixam-devices-table.component.html',
  styleUrls: ['./gixam-devices-table.component.scss']
})
export class GixamDevicesTableComponent {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  
  amountPerPageOptions = [10, 20, 50, 100];
  perPageControl = new FormControl<number |null>(this.amountPerPageOptions[0]);
  perPageChanged$ = this.perPageControl.valueChanges.pipe(debounceTime(200), map(value => +value!));

  deviceStatesEnum = GixamDeviceStatesEnum;
  deviceStatusesEnum = GixamDeviceStatusesEnum;

  devicesTableDataSource = new GixamDevicesTableDataSource(this.devicesDataService,this.devicesTableService, this.deviceHubService, this.loaderService);

  searchOptions$ = this.devicesTableService.searchOptions$;
  isOnline$ = this.devicesTableDataSource.isOnline$;

  pageRowsCount$ = this.devicesTableDataSource.pageRows$.pipe(map(rows => rows ? rows.length : 0), shareReplay(1));
  totalRowsCount$ = this.devicesTableDataSource.totalRowsCount$;

  // isLoading$ = this.devicesTableDataSource.isLoading$;

  displayedColumns:string[] = ['deviceName', 'location', 'state', 'status','recharge'];
  
  constructor(private router:Router,
    private activatedRoute:ActivatedRoute,
    private loaderService:NgxUiLoaderService,
    private devicesDataService:GixamDevicesDataService,
    private devicesTableService:GixamDevicesTableService,
    private deviceHubService:DeviceHubService){}

    ngOnInit(){
    
      this.devicesTableService.updatePageSize(this.amountPerPageOptions[0]);

      this.perPageChanged$.pipe().subscribe(
        pageSize => {
          this.devicesTableService.updatePageSize(pageSize);
        });

    }

    
  goToDevice(deviceId:any){
    this.router.navigate(['gixam','device',deviceId]);
  }

  trackByFunction(index: number, item: any) {
    return item.id;
  }

  pageChange(event: PageEvent) {
    this.devicesTableService.updatePageNumber(event.pageIndex);
  }

  rechargeOrder(id:string){
    alert(id);
  }

  deviceSortChanged(sortState: Sort){
    
    this.devicesTableService.updateSortColumn(sortState.active,sortState.direction);
  }  
}
