<div class="dialog-wrapper file-upload-dialog">
    <div class="dialog-title">
        <button class="close-button" [mat-dialog-close]="false" type="button" tabindex="-1" mat-icon-button
            aria-label="Close">
            <mat-icon class="material-icons notranslate">clear</mat-icon>
        </button>
        <h3>{{'IMPORT_STUDIES' | translate}}</h3>
    </div>

    <div class="dialog-content">

        <div class="upload-zone" (click)="this.showImportResult = false; this.selectedFiles =undefined;;fileInput.click()">

            <mat-icon class="material-icons-symbols" color="default">cloud_upload</mat-icon>

            <span color="primary" style="color: #fff;            
            border-radius: 25px;
            background-color: #db202f;
            padding: .5rem 1rem;">Browse files</span>         
        </div>

        <input type="file" style="display: none;" #fileInput (change)="onFilesSelected($event)" webkitDirectory/>

        <div class="files-list">
            <div class="single-file">
                <div class="file-icon" style="width: 50px">
                    <svg xml:space="preserve" xmlns:xlink="http://www.w3.org/1999/xlink" id="Capa_1"
                        style="enable-background:new 0 0 58 58;" version="1.1" viewBox="0 0 58 58" x="0px"
                        xmlns="http://www.w3.org/2000/svg" y="0px">
                        <polygon points="51.5,14 37.5,0 6.5,0 6.5,58 51.5,58 " style="fill:#EDEADA;"></polygon>
                        <g>
                            <path
                                d="M16.5,23h25c0.552,0,1-0.447,1-1s-0.448-1-1-1h-25c-0.552,0-1,0.447-1,1S15.948,23,16.5,23z"
                                style="fill:#CEC9AE;"></path>
                            <path
                                d="M16.5,15h10c0.552,0,1-0.447,1-1s-0.448-1-1-1h-10c-0.552,0-1,0.447-1,1S15.948,15,16.5,15z"
                                style="fill:#CEC9AE;"></path>
                            <path
                                d="M41.5,29h-25c-0.552,0-1,0.447-1,1s0.448,1,1,1h25c0.552,0,1-0.447,1-1S42.052,29,41.5,29z"
                                style="fill:#CEC9AE;"></path>
                            <path
                                d="M41.5,37h-25c-0.552,0-1,0.447-1,1s0.448,1,1,1h25c0.552,0,1-0.447,1-1S42.052,37,41.5,37z"
                                style="fill:#CEC9AE;"></path>
                            <path
                                d="M41.5,45h-25c-0.552,0-1,0.447-1,1s0.448,1,1,1h25c0.552,0,1-0.447,1-1S42.052,45,41.5,45z"
                                style="fill:#CEC9AE;"></path>
                        </g>
                        <polygon points="37.5,0 37.5,14 51.5,14 " style="fill:#CEC9AE;"></polygon>
                    </svg>
                </div>
                <div class="info">
                    <!-- <span class="name">{{fileName}}</span> -->
                    <span _class="size" >{{formatUploadedSize(totalFilesSize)}}/{{formatUploadedSize(totalFilesSize)}} </span>
                    <span _class="size" *ngIf="totalPercentageUploaded == 100">file uploaded</span>
                    <mat-progress-bar mode="determinate" value="{{totalPercentageUploaded}}"></mat-progress-bar>
                </div>
                <div class="delete" (click)="cancelUpload()" *ngIf="totalPercentageUploaded < 100">

                    <svg height="18" viewBox="0 0 14 18" width="14" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M1 16c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2H3c-1.1 0-2 .9-2 2v10zm3.17-7.83a.996.996 0 0 1 1.41 0L7 9.59l1.42-1.42a.996.996 0 1 1 1.41 1.41L8.41 11l1.42 1.42a.996.996 0 1 1-1.41 1.41L7 12.41l-1.42 1.42a.996.996 0 1 1-1.41-1.41L5.59 11 4.17 9.58a.996.996 0 0 1 0-1.41zM10.5 1L9.79.29C9.61.11 9.35 0 9.09 0H4.91c-.26 0-.52.11-.7.29L3.5 1H1c-.55 0-1 .45-1 1s.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1h-2.5z"
                            fill="#B1B1B1" fill-rule="nonzero">
                        </path>
                    </svg>
                </div>
            </div>
        </div>

        <div class="import-result" *ngIf="showImportResult">
            <span>Import finished successfully</span>
        </div>
    </div>
</div>