import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError, map, Observable, of, shareReplay, switchMap, throwError } from 'rxjs';
import { file } from 'jszip';

@Injectable({
  providedIn: 'root'
})
export class GixamFilesService {

  private endpointPath$ = of(environment.apiUrl + 'api/import');
  private studiesEndpointPath$ = of(environment.apiUrl + 'api/studies');
  constructor(private http: HttpClient) { }

  public upload(formData: FormData): Observable<any>{

    let uploadedBytes = 0;

    let chunkUploadedBytes = 0;

        return this.studiesEndpointPath$.pipe(
          switchMap(studiesEndpointPath => this.http.post<any>(`${studiesEndpointPath}/upload-files`, 
            formData,
            { reportProgress: true, observe: 'events' })),
          map(event=>{ 
            if (event.type === HttpEventType.UploadProgress && event.total) {    
              
              const chunkProgress = event.loaded - chunkUploadedBytes;
              chunkUploadedBytes = event.loaded;

              uploadedBytes += chunkProgress;
             
              const progress = {                
                uploadedBytes: uploadedBytes
              };

              return progress;
              
            } 
            else if (event.type == HttpEventType.Response){
              const progress = {                
                uploadedBytes: uploadedBytes
              };   
              return progress;  
            } 
            else{
              const progress = {                
                uploadedBytes: uploadedBytes
              };   
              return progress;  
            }
          }),
          catchError(err => throwError(() => new Error(err))))
          
    // const uploadFile = (formData:FormData): Observable<any> => {

    //   return new Observable(observer =>{

                 
    //   }); 
    // }    

    // return uploadFile(formData);
  }

  uploadZipFile(model: any) {
    return this.studiesEndpointPath$.pipe(
      switchMap(studiesEndpointBase => this.http.post<any>(`${studiesEndpointBase}/import`, model, { reportProgress: true, observe: 'events' })),
      catchError(err => throwError(() => new Error(err))));
  }

  syncWithStorage(deviceId: string) {
    return this.studiesEndpointPath$.pipe(
      switchMap(studiesEndpointBase => this.http.get<any>(`${studiesEndpointBase}/${deviceId}/sync`)),
      catchError(err => throwError(() => new Error(err))));
  }

  downloadFile(model: any) {

  }

  formatFileSize(bytes: number, decimals = 2) {

    if (!+bytes) return '0 Bytes'

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  }

  uploadFileInChunks(file: File, destinationFileName: string, chunkSize = 50 * 1024 * 1024): Observable<any> {
    const totalChunks = Math.ceil(file.size / chunkSize);
    let uploadedChunks = 0;
    let uploadedBytes = 0;

    const uploadChunk = (chunkIndex: number): Observable<any> => {
      const start = chunkIndex * chunkSize;
      const end = Math.min(start + chunkSize, file.size);
      const chunk = file.slice(start, end);

      const formData = new FormData();
      formData.append('file', chunk, `${file.name}`);
      formData.append('destinationFileName', destinationFileName);
      formData.append('chunkIndex', chunkIndex.toString());
      formData.append('totalChunks', totalChunks.toString());
      formData.append('totalSize', file.size.toString());

      return new Observable(observer => {
        let chunkUploadedBytes = 0;

        this.studiesEndpointPath$
          .pipe(
            switchMap(studiesEndpointBase => this.http.post<any>(
              `${studiesEndpointBase}/upload`,
              formData,
              {
                reportProgress: true,
                observe: 'events'
              }
            )),
            catchError(err => {
              observer.error(new Error(`Error uploading chunk ${chunkIndex}: ${err.message}`));
              return throwError(() => err);
            })
          )
          .subscribe(event => {
            if (event.type === HttpEventType.UploadProgress && event.total) {
              const chunkProgress = event.loaded - chunkUploadedBytes;
              chunkUploadedBytes = event.loaded;

              uploadedBytes += chunkProgress;

              console.log(`Chunk: ${chunkIndex}; Uploaded Bytes: ${this.formatFileSize(uploadedBytes)})`);

              const progress = {
                percentage: Math.round((uploadedBytes / file.size) * 100),
                uploadedBytes: uploadedBytes
              };

              observer.next(progress);

            } else if (event.type === HttpEventType.Response) {
              uploadedChunks++;
              console.log(`Chunk: ${chunkIndex}; Total Uploaded: ${this.formatFileSize(uploadedBytes)})`);

              if (chunkIndex + 1 < totalChunks) {
                uploadChunk(chunkIndex + 1).subscribe(observer);
              }
              else {

                const progress = {
                  percentage: Math.round((uploadedBytes / file.size) * 100),
                  uploadedBytes: uploadedBytes,
                  message: `File ${file.name} uploaded successfully.`
                };

                observer.next(progress);

                observer.complete()
              }
            }
          });
      });




      // return new Observable(observer => {

      //   return this.studiesEndpointPath$
      //     .pipe(
      //       switchMap(studiesEndpointBase => this.http.post<any>(
      //         `${studiesEndpointBase}/upload`,
      //         formData,
      //         { reportProgress: true, observe: 'events' }
      //       )),
      //       catchError(err => throwError(() => new Error(err))))
      //     .subscribe(event => {
      //       if (event.type === HttpEventType.UploadProgress) {
      //         const progress = {
      //           percantage: event.total ? Math.round((100 * (event.loaded + 1)) / event.total) : 0,
      //           size: event.loaded,
      //           message: `Part ${chunkIndex} out of ${totalChunks} was uploaded successfully`
      //         };
      //         observer.next(progress);
      //       } else if (event.type === HttpEventType.Response) {
      //         uploadedChunks++;
      //         if (chunkIndex + 1 < totalChunks) {
      //           uploadChunk(chunkIndex + 1).subscribe(observer);
      //         } else {
      //           observer.complete();
      //         }
      //       }
      //     });
      // });

    };

    return uploadChunk(0);
  }
}
